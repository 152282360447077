import Vue from 'vue'
import axios from 'axios'

/* eslint-disable */

const baseURL = process.env.VUE_APP_API_URL

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL,
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  headers: {'X-MedKnit-Version': '2021.08.01'}
})

axiosIns.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    throw new Error(`UnAuthorized: ${error.response.data.detail}`)
  }

  if (error.response.status === 404) {
    throw new Error(`404: ${error.response.data.detail}`)
  }

  throw new Error(`${error.response.data.detail}`)
})

// Add a request interceptor
axiosIns.interceptors.request.use(config => {
  if (config && config.excludeAuthHeader) {
    delete config['headers']['common']['Authorization']
  }
  return config
}, error => {
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
