import axios from '@/libs/axios'

export const state = {
}

export const getters = {
}

export const mutations = {

}

export const actions = {
  createPotentialUser(_, email) {
    return new Promise((resolve, reject) => {
      const url = '/users/potentials/'
      axios.post(url, { email }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  verifyPotentialUser(_, email) {
    return new Promise((resolve, reject) => {
      const url = '/users/potentials/'
      axios.patch(url, { email }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
}
